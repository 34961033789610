import { Link, Stack, Text, useDeviceSize } from '@questbound/compass-core-ui';
import { InfoSection } from '../components/info-section';
import { VideoPlayer } from '../components/video-player';
import diceVideo from '../assets/dice.mp4';

export const Dice = () => {
  const { mobile } = useDeviceSize();
  return (
    <InfoSection title='Sync Rolls with Virtual Tabletops'>
      <Stack spacing={2}>
        <Stack direction={mobile ? 'column' : 'row'} spacing={1} justifyContent='center'>
          <Text sx={{ textAlign: 'center', fontSize: '1.2rem' }}>
            Roll between devices and virtual tabletops with
          </Text>
          <Link
            href='https://dddice.com'
            target='_blank'
            sx={{
              textDecoration: 'underline',
              color: 'common.white',
              '&:hover': { color: 'grey' },
            }}>
            <Text sx={{ textAlign: 'center', fontSize: '1.2rem' }}>dddice</Text>
          </Link>
        </Stack>
        {!mobile && <VideoPlayer src={diceVideo} autoplay />}
      </Stack>
    </InfoSection>
  );
};
