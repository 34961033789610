import { Divider as CoreDivider } from '@questbound/compass-core-ui';
import { motion } from 'framer-motion';

export const Divider = () => (
  <motion.section
    initial={{ opacity: 0 }}
    whileInView={{ opacity: 1 }}
    transition={{ duration: 2 }}>
    <CoreDivider
      sx={{
        width: '95vw',
        margin: 'auto',
        mt: 2,
        borderWidth: 1,
        borderColor: 'common.white',
      }}
    />
  </motion.section>
);
