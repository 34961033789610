import { Check } from '@mui/icons-material';
import {
  MenuItem,
  Select,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Text,
  useDeviceSize,
} from '@questbound/compass-core-ui';
import { InfoSection } from '../components/info-section';
import { useState } from 'react';
import { Kickstarter } from './kickstarter';

const DISABLE_TABLE = true;

export const Price = () => {
  const { mobile } = useDeviceSize();
  const [selection, setSelection] = useState<string>('free');

  const price = {
    free: 'Free',
    creator: '$9 / Month',
    publisher: 'Reach Out to publishing@questbound.com',
  };

  return (
    <InfoSection title='Pricing'>
      <Stack
        sx={{
          maxWidth: 1400,
          margin: 'auto',
          width: `100dvw`,
          overflow: 'auto',
          padding: 2,
        }}
        justifyContent='center'
        alignItems='center'>
        {DISABLE_TABLE ? (
          <Stack spacing={2} alignItems='center'>
            <Text fontSize='1.3rem' color='secondary'>
              Quest Bound will be available as a desktop application for a one-time fee of $20
            </Text>
            <Text fontSize='1.1rem'>Try the web application for free until the end of 2024.</Text>
            <Kickstarter />
          </Stack>
        ) : (
          <>
            <Text color='secondary' sx={{ mb: 2 }} fontSize='1.1rem'>
              All creator tools and features are free to use
            </Text>
            {mobile && (
              <Stack padding={2} alignItems='center' justifyContent='center'>
                <Select
                  id='tier-select'
                  sx={{ width: '250px' }}
                  value={selection}
                  onChange={(e) => setSelection(e.target.value as string)}>
                  <MenuItem value='free'>Players & Game Masters</MenuItem>
                  <MenuItem value='creator'>Hobbyist Creators</MenuItem>
                  <MenuItem value='publisher'>Publishers</MenuItem>
                </Select>
                <Text fontSize='1.2rem' textAlign='center'>
                  {price[selection as keyof typeof price]}
                </Text>
              </Stack>
            )}
            <Table>
              <TableHead>
                {!mobile && (
                  <TableRow>
                    <TableCell sx={{ textAlign: 'center' }}>
                      <Text variant='h4'></Text>
                    </TableCell>
                    <TableCell sx={{ textAlign: 'center' }}>
                      <Text variant='h5'>Free</Text>
                    </TableCell>
                    <TableCell sx={{ textAlign: 'center' }}>
                      <Stack>
                        <Text variant='h5'>$9 / Month</Text>
                        <Text>$7.50 / month paid annually</Text>
                      </Stack>
                    </TableCell>
                    <TableCell sx={{ textAlign: 'center' }}>
                      <Stack>
                        <Text variant='h5'>Reach Out</Text>
                        <Text>publishing@questbound.com</Text>
                      </Stack>
                    </TableCell>
                  </TableRow>
                )}
              </TableHead>
              <TableBody>
                {!mobile && (
                  <TableRow>
                    <TableCell sx={{ textAlign: 'center' }}>
                      <Text></Text>
                    </TableCell>
                    <TableCell sx={{ textAlign: 'center' }}>
                      <Text fontStyle='italic' fontSize='0.9rem'>
                        Players & Game Masters
                      </Text>
                    </TableCell>
                    <TableCell sx={{ textAlign: 'center' }}>
                      <Text fontStyle='italic' fontSize='0.9rem'>
                        Professional GMs & Hobbyist Creators
                      </Text>
                    </TableCell>
                    <TableCell sx={{ textAlign: 'center' }}>
                      <Text fontStyle='italic' fontSize='0.9rem'>
                        Publishers
                      </Text>
                    </TableCell>
                  </TableRow>
                )}

                <Row selection={selection} feature='Rulesets on your Shelf' free='Unlimited' />

                <Row
                  selection={selection}
                  feature='Characters'
                  free='1 per ruleset'
                  creator='Unlimited'
                />

                <Row selection={selection} feature='Custom Rulesets' free='1' creator='Unlimited' />

                <Row
                  selection={selection}
                  feature='Add Players to Custom Rulesets'
                  free='5'
                  creator='15 per ruleset'
                  pro='Unlimited'
                />

                <Row
                  selection={selection}
                  feature='Published Rulesets'
                  creator='1'
                  pro='Unlimited'
                />

                <Row
                  selection={selection}
                  feature='Sales Commission*'
                  creator='15%'
                  pro='Variable'
                />

                <Row selection={selection} feature='Collaborators' pro />

                {/* <Row selection={selection} feature='External Customers*' pro='100' /> */}

                <Row selection={selection} feature='Priority Support' pro />

                <Row selection={selection} feature='Priority Feature Requests' pro />

                <Row selection={selection} feature='Verified Content**' pro />
              </TableBody>
            </Table>
            <Stack mt={2} width='100%'>
              {/* <Text sx={{ fontStyle: 'italic', fontSize: '0.85rem' }}>
              *External customers have access to your rulesets without needing to purchase them
              through Quest Bound
            </Text> */}
              <Text sx={{ fontStyle: 'italic', fontSize: '0.85rem' }}>
                *The percentage of the sale price that Quest Bound retains
              </Text>
              <Text sx={{ fontStyle: 'italic', fontSize: '0.85rem' }}>
                **Work with Quest Bound to ensure a quality experience for your players, then
                receive a verified badge on your ruleset
              </Text>
            </Stack>
          </>
        )}
      </Stack>
    </InfoSection>
  );
};

interface RowProps {
  feature: string;
  free?: string | boolean;
  creator?: string | boolean;
  pro?: string | boolean;
  publisher?: string | boolean;
  selection?: string;
}

function Row({ feature, free, creator, pro, publisher, selection: _selection }: RowProps) {
  const { mobile } = useDeviceSize();
  const selection = mobile ? _selection : undefined;
  const Description = ({ available }: { available?: string | boolean }) =>
    available === true ? <Check /> : !!available ? <Text>{available}</Text> : <Text>-</Text>;

  return (
    <TableRow>
      <TableCell
        sx={{
          textAlign: 'center',
          width: '150px',
        }}>
        <Text>{feature}</Text>
      </TableCell>
      {(!selection || selection === 'free') && (
        <TableCell sx={{ textAlign: 'center' }}>{<Description available={free} />}</TableCell>
      )}
      {(!selection || selection === 'creator') && (
        <TableCell sx={{ textAlign: 'center' }}>
          {<Description available={creator ?? free} />}
        </TableCell>
      )}
      {(!selection || selection === 'publisher') && (
        <TableCell sx={{ textAlign: 'center' }}>
          {<Description available={publisher ?? pro ?? creator ?? free} />}
        </TableCell>
      )}
    </TableRow>
  );
}
