import { Grid, Stack, Text, useDeviceSize } from '@questbound/compass-core-ui';
import { motion } from 'framer-motion';
import { SignUp } from '../components/sign-up';

export const HeroSplash = () => {
  const { mobile } = useDeviceSize();
  return (
    <Grid xs={12} lg={7} sx={{ mb: 8 }}>
      <Stack height='100%' width='100%' padding={1} paddingTop={mobile ? 3 : 8} alignItems='center'>
        <motion.div initial={{ opacity: 0 }} whileInView={{ opacity: 1 }}>
          <Stack spacing={2} alignItems='center'>
            <Text
              variant='h2'
              sx={{
                fontFamily: 'CygnitoMonoPro-BoldR',
                textAlign: 'center',
              }}>
              Make Your Own Rules with
            </Text>
            <Text
              variant='h2'
              color='secondary'
              sx={{
                fontFamily: 'CygnitoMonoPro-BoldR',
                textAlign: 'center',
              }}>
              Quest Bound
            </Text>

            <Text
              sx={{
                fontSize: '1.2rem',
                maxWidth: 500,
                textAlign: 'center',
              }}>
              Elevate the digital version of your tabletop game using drag and drop editors and
              visual programming
            </Text>
          </Stack>
        </motion.div>

        <Stack>
          <motion.div initial={{ opacity: 0 }} whileInView={{ opacity: 1 }}>
            <SignUp />
          </motion.div>
        </Stack>
      </Stack>
    </Grid>
  );
};
