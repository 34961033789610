import { Form, FormInput, Stack, Text } from '@questbound/compass-core-ui';
import { NotificationContext, NotificationPriority } from '@questbound/compass-web-utils';
import { useContext, useState } from 'react';
import * as yup from 'yup';
import { gtag, install } from 'ga-gtag';
import axios from 'axios';

install('AW-11418197115/53B8CKTQuvgYEPu4z8Qq');

const SIGNUP_ENDPOINT = 'https://api.questbound.com/signup';
// const SIGNUP_ENDPOINT = 'http://localhost:8000/signup';

const SUCCESS_ENDPOINT = 'https://questbound.com';
// const SUCCESS_ENDPOINT = 'http://localhost:5173';

function gtag_report_conversion() {
  gtag('event', 'conversion', {
    send_to: 'AW-11418197115/53B8CKTQuvgYEPu4z8Qq',
  });
  return false;
}

type FormValues = {
  email: string;
  requestAccess: boolean;
};

export const validationSchema = yup.object({
  email: yup.string().email('Enter a valid email').required('Email is required'),
});

export const SignUp = () => {
  const { addNotification } = useContext(NotificationContext);
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (values: FormValues) => {
    if (!values) return;
    try {
      setLoading(true);

      const res = await axios.post(SIGNUP_ENDPOINT, { email: values.email });

      if (res.data) {
        gtag_report_conversion();
        window.location.href = SUCCESS_ENDPOINT;
      }
    } catch (e: any) {
      const message = e.message.includes('409')
        ? `A user with the email ${values.email} already exists`
        : e.message;
      addNotification({
        message,
        status: e.message.includes('409') ? 'info' : 'error',
        priority: NotificationPriority.LOW,
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Stack justifyContent='center' sx={{ margin: 'auto', mt: 8, gap: 1 }} spacing={1}>
        <Stack justifyContent='center' alignItems='center' spacing={1}>
          <Stack
            alignItems='center'
            direction='row'
            spacing={1}
            width='100%'
            justifyContent='center'>
            <Text fontSize='1.3rem'>Use the free online version until 2025</Text>
          </Stack>
          <Form
            validationSchema={validationSchema}
            style={{ marginTop: 24 }}
            onSubmit={(values) => {
              handleSubmit(values);
            }}
            initialValues={{ email: '' }}
            actions={[{ label: 'Sign Up', isSubmit: true, loading }]}
            center>
            <FormInput autoComplete label='Email' />
          </Form>
        </Stack>
      </Stack>
    </>
  );
};
