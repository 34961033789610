import {
  CompassThemeProvider,
  CssBaseline,
  Stack,
  useDeviceSize,
  Text,
  Link,
} from '@questbound/compass-core-ui';
import './index.css';
import {
  EnvProvider,
  NotificationProvider,
  RenderNotifications,
  useNotificationState,
} from '@questbound/compass-web-utils';
import { StytchUIClient } from '@stytch/vanilla-js';
import { StytchProvider } from '@stytch/react';
import { Divider } from '../components/divider';
import { motion } from 'framer-motion';
import { SignUp } from '../components/sign-up';
import { FAQ } from '../sections/faq';
import { CacheProvider } from '@questbound/compass-api';
import { HeroSplash } from '../sections/hero-splash';
import { NavBar } from '../components/nav-bar';
import introVideo from '../assets/landing-video.mp4';
import introPoster from '../assets/qb-cover.png';
import { Price } from '../sections/price';
import { useEffect, useRef } from 'react';
import { TabletopGameEngine } from '../sections/tabletop-game-engine';
import { Dice } from '../sections/dice';
import { Testimonials } from '../sections/testimonials';
import { PlayerControl } from '../sections/player-control';
import { MissionStatement } from '../sections/mission-statement';
import { Logic } from '../sections/logic';
import { Kickstarter } from '../sections/kickstarter';

const STYTCH_PUBLIC_KEY = import.meta.env.VITE_STYTCH_PUBLIC_KEY ?? '';
let stytchClient: any | null = null;

const SUPABASE_KEY = import.meta.env.VITE_SUPABASE_KEY ?? '';
const SUPABASE_HOST = import.meta.env.VITE_SUPABASE_HOST ?? '';

const getStytchClient = () => {
  if (!stytchClient) {
    stytchClient = new StytchUIClient(STYTCH_PUBLIC_KEY);
  }

  return stytchClient;
};

const IndexPage = () => {
  const { mobile } = useDeviceSize();
  const stytchClient = getStytchClient();

  const videoRef = useRef<HTMLVideoElement>(null);
  const delay = mobile ? 0 : 0.5;

  useEffect(() => {
    setTimeout(() => {
      if (videoRef.current) {
        videoRef.current.play();
      }
    }, delay * 1000);
  }, []);

  return (
    <>
      <StytchProvider stytch={stytchClient}>
        <CompassThemeProvider>
          <NotificationProvider value={useNotificationState()}>
            <EnvProvider value={{ environment: 'dev', domain: '' }}>
              <CacheProvider
                {...{
                  supabaseKey: SUPABASE_KEY,
                  supabaseHost: SUPABASE_HOST,
                  emailApiEndpoint: 'https://dev.emails.questbound.com',
                  // Not needed for landing page
                  graphqlEndpoint: 'https://alpha.api.questbound.com/graphql',
                  fileApiEndpoint: '',
                  deleteFileApiEndpoint: '',
                  copyFileApiEndpoint: '',
                }}>
                <CssBaseline />
                <NavBar />
                <main
                  style={{
                    overflowX: 'hidden',
                    margin: 'auto',
                    padding: '16px',
                    paddingTop: '60px',
                    backgroundColor: '#2a2a2a',
                    maxWidth: '1450px',
                  }}>
                  <HeroSplash />

                  <Kickstarter />

                  <motion.section
                    initial={{ opacity: 0, scale: 0.9 }}
                    animate={{ opacity: 1, scale: 1 }}
                    transition={{ duration: 0.5, delay }}>
                    <video
                      ref={videoRef}
                      src={introVideo}
                      style={{ width: '100%', minHeight: 200 }}
                      preload='auto'
                      controls={mobile}
                      loop
                      muted
                      playsInline
                      poster={introPoster}
                    />
                  </motion.section>

                  <MissionStatement />

                  <Testimonials />

                  <TabletopGameEngine />

                  <Logic />

                  <PlayerControl />

                  <Dice />

                  <Price />

                  <Divider />

                  <FAQ />

                  <Divider />

                  <Stack
                    width={mobile ? '100%' : '80%'}
                    alignItems='center'
                    justifyContent='center'
                    direction={mobile ? 'column' : 'row'}
                    sx={{ margin: 'auto', mb: 8, mt: 8 }}>
                    <img
                      alt='Quest Bound'
                      src='/qb-banner.svg'
                      width='50%'
                      style={{ maxWidth: 750 }}
                    />
                  </Stack>

                  <Divider />

                  <Stack>
                    <SignUp />
                  </Stack>

                  <Stack direction='row' width='100%' minHeight={200} justifyContent='space-around'>
                    <Stack padding={4} mb={4} spacing={2}>
                      <Link
                        href='https://questbound.com'
                        sx={{
                          textDecoration: 'none',
                          color: 'common.white',
                          '&:hover': { color: 'grey' },
                        }}>
                        <Text>Launch Quest Bound</Text>
                      </Link>
                      <Link
                        href='https://docs.questbound.com'
                        sx={{
                          textDecoration: 'none',
                          color: 'common.white',
                          '&:hover': { color: 'grey' },
                        }}>
                        <Text>Docs</Text>
                      </Link>
                      <Link
                        href='https://blog.questbound.com'
                        target='_blank'
                        sx={{
                          textDecoration: 'none',
                          color: 'common.white',
                          '&:hover': { color: 'grey' },
                        }}>
                        <Text>Dev/Log</Text>
                      </Link>
                      <Link
                        href='https://docs.questbound.com/docs/terms'
                        sx={{
                          textDecoration: 'none',
                          color: 'common.white',
                          '&:hover': { color: 'grey' },
                        }}>
                        <Text>Terms of Service</Text>
                      </Link>
                    </Stack>
                    <Stack padding={4} mb={4} spacing={2}>
                      <Link
                        href='https://www.reddit.com/r/quest_bound'
                        target='_blank'
                        sx={{
                          textDecoration: 'none',
                          color: 'common.white',
                          '&:hover': { color: 'grey' },
                        }}>
                        <Text>Reddit</Text>
                      </Link>
                      <Link
                        href='https://discord.gg/7QGV4muT39'
                        target='_blank'
                        sx={{
                          textDecoration: 'none',
                          color: 'common.white',
                          '&:hover': { color: 'grey' },
                        }}>
                        <Text>Discord</Text>
                      </Link>
                      <Link
                        href='https://www.instagram.com/quest_bound/'
                        target='_blank'
                        sx={{
                          textDecoration: 'none',
                          color: 'common.white',
                          '&:hover': { color: 'grey' },
                        }}>
                        <Text>Instagram</Text>
                      </Link>
                      <Link
                        href='https://www.youtube.com/@Quest_Bound'
                        target='_blank'
                        sx={{
                          textDecoration: 'none',
                          color: 'common.white',
                          '&:hover': { color: 'grey' },
                        }}>
                        <Text>YouTube</Text>
                      </Link>
                      <Link
                        href='https://www.tiktok.com/@quest_bound'
                        target='_blank'
                        sx={{
                          textDecoration: 'none',
                          color: 'common.white',
                          '&:hover': { color: 'grey' },
                        }}>
                        <Text>TikTok</Text>
                      </Link>
                    </Stack>
                  </Stack>
                </main>
              </CacheProvider>
            </EnvProvider>
            <RenderNotifications />
          </NotificationProvider>
        </CompassThemeProvider>
      </StytchProvider>
    </>
  );
};

export default IndexPage;
