import { ExpandMore } from '@mui/icons-material';
import {
  Stack,
  Text,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  useDeviceSize,
} from '@questbound/compass-core-ui';
import { features } from './features';

export const FAQ = () => {
  const { mobile } = useDeviceSize();

  return (
    <Stack
      sx={{ margin: 'auto', mt: 6, mb: 6 }}
      padding={2}
      spacing={2}
      maxWidth={820}
      width='100%'>
      {features.map((f) => (
        <Accordion key={f.title}>
          <AccordionSummary expandIcon={<ExpandMore />}>
            <Stack direction={'row'} alignItems='center' spacing={2}>
              <Text variant='h5' maxWidth={!!f.status && mobile ? '60%' : '100%'}>
                {f.title}
              </Text>
              {f.status && (
                <Text sx={{ fontStyle: 'italic', color: 'water.main' }}>{f.status}</Text>
              )}
            </Stack>
          </AccordionSummary>
          <AccordionDetails>{f.description}</AccordionDetails>
        </Accordion>
      ))}
    </Stack>
  );
};
