import { Stack, LogoIcon, Link, Text, useDeviceSize } from '@questbound/compass-core-ui';

export const NavBar = () => {
  const { mobile } = useDeviceSize();
  const scrollToElement = (id: string) => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <Stack
      sx={{
        position: 'fixed',
        zIndex: 1000,
        width: '95dvw',
        backgroundColor: 'rgba(42,42,42,0.8)',
      }}
      alignItems='center'>
      <Stack
        sx={{ position: 'relative', maxWidth: 1200 }}
        height={60}
        width='100vw'
        direction='row'
        alignItems='center'
        justifyContent='space-between'
        padding={1}
        pl={2}
        pr={2}>
        <Stack direction='row' spacing={2} alignItems='center'>
          <LogoIcon />
          {!mobile && <Text sx={{ fontFamily: 'CygnitoMonoPro-BoldR' }}>Quest Bound</Text>}
        </Stack>
        <Stack direction='row' spacing={4}>
          <Link
            href='https://questbound.com'
            target='_blank'
            sx={{ textDecoration: 'none', color: 'common.white', '&:hover': { color: 'grey' } }}>
            Launch
          </Link>
          <Link
            href='https://docs.questbound.com'
            target='_blank'
            sx={{ textDecoration: 'none', color: 'common.white', '&:hover': { color: 'grey' } }}>
            Docs
          </Link>
          <Link
            href='https://blog.questbound.com'
            target='_blank'
            sx={{ textDecoration: 'none', color: 'common.white', '&:hover': { color: 'grey' } }}>
            Dev/Log
          </Link>
          <Text
            sx={{
              textDecoration: 'none',
              color: 'common.white',
              '&:hover': { color: 'grey', cursor: 'pointer' },
            }}
            onClick={() => scrollToElement('pricing')}>
            Pricing
          </Text>
        </Stack>
      </Stack>
    </Stack>
  );
};
