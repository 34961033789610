import { Text, Stack } from '@questbound/compass-core-ui';
import kickstarterImg from '../assets/kickstarter.png';

export const Kickstarter = () => {
  return (
    <Stack spacing={4} padding={4} mb={4} alignItems='center' justifyContent='center' width='100%'>
      <a
        target='_blank'
        href='https://www.kickstarter.com/projects/quest-bound/quest-bound-tabletop-game-engine-desktop-app'
        style={{ color: 'inherit', textDecoration: 'none' }}>
        <Stack width='100%' spacing={2} alignItems='center' className='clickable'>
          <Text
            variant='h5'
            sx={{
              maxWidth: 600,
              fontFamily: 'CygnitoMonoPro-BoldR',
              textAlign: 'center',
            }}>
            Help launch Quest Bound as a desktop application by supporting us on
          </Text>
          <img src={kickstarterImg} alt='Kickstarter' width='50%' />
          <Text
            sx={{
              maxWidth: 600,
              fontSize: '1rem',
              textAlign: 'center',
            }}>
            Secure your copy at a 50% discount during the campaign
          </Text>
        </Stack>
      </a>
    </Stack>
  );
};
