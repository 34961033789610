import { Text, Stack, useDeviceSize } from '@questbound/compass-core-ui';
import { Divider } from '../components/divider';
import { motion } from 'framer-motion';
import { AutoStories, Backpack, ContactPage, Laptop, Person } from '@mui/icons-material';

export const PlayerControl = () => {
  const { mobile } = useDeviceSize();
  return (
    <motion.div initial={{ opacity: 0 }} whileInView={{ opacity: 1 }}>
      <Stack spacing={4} padding={4} mt={4} mb={4} alignItems='center'>
        <Divider />

        <Stack spacing={16}>
          <Stack
            direction={mobile ? 'column' : 'row'}
            width='100%'
            justifyContent='space-between'
            gap={4}>
            <motion.div
              initial={{ transform: 'translateX(-200px)', display: 'flex' }}
              whileInView={{ transform: 'translateX(0px)' }}
              transition={{ duration: 0.5 }}>
              <Text
                sx={{
                  width: 600,
                  maxWidth: mobile ? '90dvw' : '50dvw',
                  fontFamily: 'CygnitoMonoPro-BoldR',
                }}
                variant='h3'>
                Give your players the control they deserve
              </Text>
            </motion.div>
            <Text
              sx={{
                maxWidth: 600,
                fontSize: '1.3rem',
              }}>
              Players may fully edit their character sheets and use them on any device. Manage
              character stats and inventory directly in app, all with access to{' '}
              <span style={{ color: '#E66A3C' }}>automated game mechanics.</span>
            </Text>
          </Stack>

          <Stack width='100%' spacing={8}>
            <Stack
              direction='row'
              width='100%'
              justifyContent={mobile ? 'center' : 'space-between'}
              flexWrap='wrap'
              gap={8}>
              <motion.div
                style={{ width: '40%', minWidth: '300px' }}
                initial={{ opacity: 0, transform: `translateY(${100 * 1}px)` }}
                whileInView={{ opacity: 1, transform: 'translateY(0px)' }}>
                <Stack>
                  <Text fontFamily='CygnitoMonoPro-BoldR' fontSize='2rem' textAlign='center'>
                    Stream Character Sheets
                  </Text>
                  <Stack direction='row' spacing={4} alignItems='center'>
                    <Person sx={{ fontSize: '4rem' }} />
                    <div style={{ flexGrow: 1, backgroundColor: 'white', height: 5 }} />
                    <Laptop sx={{ fontSize: '4rem' }} />
                  </Stack>
                </Stack>
              </motion.div>

              <motion.div
                style={{ width: '40%', minWidth: '300px' }}
                initial={{ opacity: 0, transform: `translateY(${100 * 2}px)` }}
                whileInView={{ opacity: 1, transform: 'translateY(0px)' }}>
                <Stack alignItems='center'>
                  <Text fontFamily='CygnitoMonoPro-BoldR' fontSize='2rem' textAlign='center'>
                    Manage Inventories
                  </Text>
                  <Backpack sx={{ fontSize: '4rem' }} />
                </Stack>
              </motion.div>
            </Stack>

            <Stack
              direction='row'
              width='100%'
              justifyContent={mobile ? 'center' : 'space-between'}
              flexWrap='wrap'
              gap={8}>
              <motion.div
                style={{ width: '40%', minWidth: '300px' }}
                initial={{ opacity: 0, transform: `translateY(${100 * 3}px)` }}
                whileInView={{ opacity: 1, transform: 'translateY(0px)' }}>
                <Stack alignItems='center'>
                  <Text fontFamily='CygnitoMonoPro-BoldR' fontSize='2rem' textAlign='center'>
                    Direct access to resource documents
                  </Text>
                  <AutoStories sx={{ fontSize: '4rem' }} />
                </Stack>
              </motion.div>

              <motion.div
                style={{ width: '40%', minWidth: '300px' }}
                initial={{ opacity: 0, transform: `translateY(${100 * 4}px)` }}
                whileInView={{ opacity: 1, transform: 'translateY(0px)' }}>
                <Stack alignItems='center'>
                  <Text fontFamily='CygnitoMonoPro-BoldR' fontSize='2rem' textAlign='center'>
                    Fully Customizable Character Sheets
                  </Text>
                  <ContactPage sx={{ fontSize: '4rem' }} />
                </Stack>
              </motion.div>
            </Stack>
          </Stack>
        </Stack>
      </Stack>
    </motion.div>
  );
};
