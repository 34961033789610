import { Text, Stack, useDeviceSize } from '@questbound/compass-core-ui';
import { Divider } from '../components/divider';
import { motion } from 'framer-motion';

export const TabletopGameEngine = () => {
  const { mobile } = useDeviceSize();
  return (
    <motion.div initial={{ opacity: 0 }} whileInView={{ opacity: 1 }}>
      <Stack spacing={4} padding={4} mt={4} mb={4} alignItems='center'>
        <Divider />

        <Stack
          direction={mobile ? 'column' : 'row'}
          width='100%'
          justifyContent='space-between'
          gap={4}>
          <motion.div
            initial={{ transform: 'translateX(-200px)', display: 'flex' }}
            whileInView={{ transform: 'translateX(0px)' }}
            transition={{ duration: 0.5 }}>
            <Text
              sx={{
                width: 600,
                maxWidth: mobile ? '90dvw' : '50dvw',
                fontFamily: 'CygnitoMonoPro-BoldR',
              }}
              variant='h3'>
              Build a dynamic version of your game without writing a single line of code
            </Text>
          </motion.div>
          <Stack spacing={1}>
            <Text
              sx={{
                maxWidth: 600,
                fontSize: '1.3rem',
              }}>
              Quest Bound rulesets have two layers--presentation and logic. Use familiar drag & drop
              tools to lay out your game's wiki, supporting documents and character sheet templates.
            </Text>
            <Text
              sx={{
                maxWidth: 600,
                fontSize: '1.3rem',
              }}>
              Then, use the node-based logic editor to visually program your game's mechanics
              directly into your content. Visual programming means that
              <span style={{ color: '#E66A3C' }}> Quest Bound supports any system.</span>
            </Text>
          </Stack>
        </Stack>
      </Stack>
    </motion.div>
  );
};
